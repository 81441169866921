import * as React from "react"
import {
  Navbar,
} from "../components/Navbar";
import { DisplayCard } from "../components/DisplayCard";
import { SEO } from "../components/SEOComponent";
import ClientCountryMessage from "shared-models/build/client/countries";
import { CheckoutCart } from "../components/CheckoutCart";
import { sendGenericPostRequest } from "../util/util";
import { WrapPageDefault, WrapPageNoHeight } from "../components/WrapPageDefault";
import Footer from "../components/Footer";


interface Props {
    pageContext: {
        countries: ClientCountryMessage[],
    }
};

// markup
const IndexPageTemplate: React.FC<Props> = ({ pageContext }) => {
    const [countries, setCountries] = React.useState<ClientCountryMessage[]>(pageContext.countries);

    React.useEffect(() => {
      getCountries();
    }, []);
  
    const getCountries = async () => {
      const [response, err] = await sendGenericPostRequest("/country/get-verified-countries", {}, "", "Failed to load the countries, please contact our team!");
      if (err !== null) {
        console.log({ response, err });
      };
      setCountries(response);
    }
    
  return (
    <main>
    <WrapPageNoHeight>
        <SEO />
        <Navbar />
    </WrapPageNoHeight>
    {/* Line */}
    <div className="tw-h-[1px] tw-bg-gray-200 tw-shadow"></div>

    <WrapPageDefault>
    {/* Countries */}
    <div className="tw-flex tw-flex-wrap tw-pt-12">
    {
    countries.map((country : ClientCountryMessage) => {
        return (
        <DisplayCard 
            ImageURL={country.ImageURL}
            Title={country.Name}
            SubTitle={`Best Month(s): ${country.BestMonthsToTravel.join(", ")}`}
            Description={`Average Price: $${country.AverageCost} ${country.Currency} per person/day`}
            Clickable={false}
            onClick={() => {}}
            extraClasses="sm:tw-pr-4 md:tw-pr-8 tw-pb-8"
            extraImageClasses="hover:tw-shadow-lg tw-cursor-pointer"
            link={`/country/${country.Name.toLowerCase()}`}
        />
        )
    })
    }
    </div>
    <CheckoutCart />
    </WrapPageDefault>
    <Footer />
    </main>
  )
}

export default IndexPageTemplate;

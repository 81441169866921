import React from "react";
import { TempFormDataContext } from "../context/temp-form-data";
import { ShoppingCart } from "react-feather";
import { Link } from "gatsby";

const CheckoutCart = () => {
    const { formData } = React.useContext(TempFormDataContext);
    const [cartSelection, setCartSelection] = React.useState<number>(0);

    React.useEffect(() => {
        const selection = Object.keys(formData.Selection).reduce((num: number, key: string) => {
            const items = Object.values(formData.Selection[key]);
            return num + items.length;
        }, 0);
        setCartSelection(selection);
    }, [formData]);

    return (
        <Link to="/discover-tour-operators">
            <div className="tw-fixed tw-bottom-[30px] tw-right-[30px] tw-z-50">
                <div className="tw-bg-safari-green tw-w-[50px] tw-h-[50px] tw-rounded-full tw-flex tw-justify-center tw-items-center tw-shadow-xl">
                    <ShoppingCart className="tw-text-white tw-mr-1" size={25} />
                    <div className="tw-bg-white tw-border-[1px] tw-absolute tw-top-[-8px] tw-right-[-8px] tw-w-[25px] tw-h-[25px] tw-rounded-full tw-flex tw-justify-center tw-items-center">
                        {cartSelection}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export {
    CheckoutCart,
}
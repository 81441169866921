import React, { ReactNode } from "react";

interface Props {
    children: ReactNode,
}

export const WrapPageDefault: React.FC<Props> = ({ children }) => {
    return (
        <div className="tw-flex tw-justify-center tw-pt-[5px] md:tw-pt-[20px] tw-pb-[20px]" id="main-app-wrapper">
            <div className="tw-max-w-[1400px] tw-px-4 tw-w-full tw-min-h-screen">
                {children}
            </div>
        </div>
    )
}

const WrapPageNoHeight: React.FC<Props> = ({ children }) => {
    return (
        <div className="tw-flex tw-justify-center tw-pt-[5px] md:tw-pt-[20px] tw-pb-[20px]" id="main-app-wrapper">
            <div className="tw-max-w-[1400px] tw-px-4 tw-w-full">
                {children}
            </div>
        </div>
    )
}

export {
    WrapPageNoHeight,
}
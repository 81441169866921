import { Link } from 'gatsby';
import * as React from 'react';

type DisplayCardProps = {
    ImageURL: string,
    Title: React.ReactNode | string,
    SubTitle: React.ReactNode | string,
    Description: React.ReactNode | string,
    Clickable: boolean,
    onClick: Function,
    extraClasses?: string,
    extraImageClasses?: string,
    link: string,
}

const DisplayCard = ({
    ImageURL,
    Title,
    SubTitle,
    Description,
    Clickable,
    onClick,
    extraClasses,
    extraImageClasses,
    link,
}: DisplayCardProps) => {

    const details = (
        <>
            <div className="tw-bg-black tw-rounded-2xl">
                <img src={ImageURL} alt={`${Title} ${SubTitle}`} className={`tw-h-[340px] tw-object-cover tw-w-full tw-rounded-2xl ${extraImageClasses}`} />
            </div>
            <div className="tw-pt-4">
                <h3 className="tw-font-bold tw-text-md hover:tw-underline">{Title}</h3>
            </div>
            <div className="tw-pt-2 tw-pb-1">
                <h4 className="tw-text-sm">{SubTitle}</h4>
            </div>
            <div>
                <h4 className="tw-text-sm tw-text-gray-500 tw-font-medium">{Description}</h4>
            </div>
        </>
    )

    if (link.length === 0) {
        return (
            <button
                className={`tw-w-full tw-text-left sm:tw-w-1/2 lg:tw-w-1/4 tw-max-w-[500px] ${extraClasses}`}
                onClick={() => {
                    if (Clickable) {
                        onClick();
                    }
                }}
            >
                {details}
            </button>
        )
    }
    return (
        <Link
            to={link}
            className={`tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 tw-max-w-[500px] ${extraClasses}`}
            onClick={() => {
                if (Clickable) {
                    onClick();
                }
            }}>
            {details}
        </Link>
    )

};

export {
    DisplayCard,
}